export function Banner() {
  return (
    <>
      <section>
        <div className="flex w-full">
          <div className="lg:flex flex-row gap-4">
            <div className="sm: pb-2 lg:pb-0">
              <a href="https://www.solidoherrajes.com/tienda/cocina/electrococina">
                <img
                  className="w-full"
                  src="/bannersSubcat/bg/Electrococina.png"
                  alt="Electrococina"
                  loading="lazy"
                />
              </a>
            </div>
            <div>
              <a href="https://www.solidoherrajes.com/tienda/hogar/electrohogar">
                <img
                  className="w-full"
                  src="/bannersSubcat/bg/Electrohogar.png"
                  alt="Electrohogar"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>{' '}
      </section>
    </>
  );
}
