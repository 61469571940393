import { useLocation } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { SVGProps } from 'react';
import { JSX } from 'react/jsx-runtime';
import CloseIcon from './ui/CloseIcon';
import {
  Filter as FilterTypes,
  FilterCategory as FilterCategoryTypes,
  Product as ProductTypes,
} from '@/types';
import { matchPath } from 'react-router-dom';
import { useFilter } from '@/hooks/useFilter';

interface FilterProps {
  filter: boolean;
  handleSetFilter: () => void;
  isLargeScreen: boolean;
  onFilterChange: (filter: string) => void;
  filtersFromSearch?: FilterTypes[];
  filtersCategoriesFromSearch?: FilterCategoryTypes[];
  products: ProductTypes[];
  selectedFilter?: string;
  loading?: boolean;
}

export default function Filter({
  filter,
  handleSetFilter,
  isLargeScreen,
  onFilterChange,
  filtersFromSearch,
  filtersCategoriesFromSearch,
  products,
  selectedFilter,
  loading,
}: FilterProps) {
  const {
    filters,
    filterCategories,
    selectedFilters,
    toggleFilter,
    clearFilters,
  } = useFilter(
    products,
    filtersFromSearch,
    filtersCategoriesFromSearch,
    selectedFilter
  );
  const location = useLocation();

  // Don't render anything if loading and no filters are selected
  if (loading && !selectedFilter) {
    return null;
  }

  const handleFilterChange = (value: string) => {
    toggleFilter(value);
    onFilterChange(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (!isLargeScreen) {
      handleSetFilter();
    }
  };

  const handleClearFilters = () => {
    const resetValue = clearFilters();
    onFilterChange(resetValue);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (
    location.pathname === '/tienda' ||
    matchPath('/tienda/producto/*', location.pathname)
  ) {
    return null;
  }

  const renderFilterSection = (
    <div className="flex flex-col gap-4">
      {filterCategories.map((filterCategory) => (
        <div
          key={filterCategory.id}
          className="border-b border-gray-100 last:border-0 pb-4 transition-all duration-200 ease-in-out"
        >
          <h4 className="text-lg font-semibold mb-3 text-gray-900 flex items-center justify-between">
            {filterCategory.attributes.name}
            {selectedFilters.size > 0 && (
              <Badge variant="secondary" className="ml-2">
                {selectedFilters.size}
              </Badge>
            )}
          </h4>
          <div className="space-y-2">
            {filters
              .filter(
                (filter) =>
                  filter.attributes.filter_category?.data?.id ===
                  filterCategory.id
              )
              .map((filter) => (
                <div
                  key={filter.id}
                  className="relative flex items-center transition-transform duration-200 ease-in-out hover:translate-x-1"
                >
                  <input
                    type="checkbox"
                    id={filter.attributes.name}
                    checked={selectedFilters.has(filter.attributes.name)}
                    onChange={() => handleFilterChange(filter.attributes.name)}
                    className="peer h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                  />
                  <Label
                    htmlFor={filter.attributes.name}
                    className="ml-3 text-sm font-medium text-gray-700 cursor-pointer hover:text-gray-900 transition-colors"
                  >
                    {filter.attributes.name}
                  </Label>
                </div>
              ))}
          </div>
        </div>
      ))}
      {selectedFilters.size > 0 && (
        <div className="transition-opacity duration-200 ease-in-out">
          <Button
            onClick={handleClearFilters}
            variant="outline"
            className="w-full text-sm hover:bg-gray-50"
          >
            Limpiar filtros ({selectedFilters.size})
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <div
      className={cn(
        'transition-all duration-200',
        isLargeScreen ? 'flex bg-white' : ''
      )}
    >
      {!isLargeScreen ? (
        <div className="p-2 lg:hidden">
          <Button
            onClick={handleSetFilter}
            variant="outline"
            className="flex items-center gap-2 px-4 py-2 rounded-lg shadow-sm hover:shadow-md transition-shadow"
          >
            <FilterIcon className="w-5 h-5" />
            <span>Filtrar</span>
            {selectedFilters.size > 0 && (
              <Badge variant="secondary">{selectedFilters.size}</Badge>
            )}
          </Button>
          {filter && (
            <div className="fixed inset-0 bg-white z-50 overflow-y-auto transition-transform duration-300 ease-in-out">
              <div className="p-6 mt-[10vh]">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-xl font-bold text-red-800">Filtros</h2>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={handleSetFilter}
                    className="rounded-full hover:bg-gray-100"
                  >
                    <CloseIcon className="w-6 h-6" />
                  </Button>
                </div>
                {renderFilterSection}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="hidden lg:block p-6 rounded-lg shadow-sm">
          <h2 className="text-xl font-bold text-red-800 mb-6">
            Filtrar Búsqueda
          </h2>
          {renderFilterSection}
        </div>
      )}
    </div>
  );
}

function FilterIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
    </svg>
  );
}
