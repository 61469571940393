import {
  CardFeatured,
  CardContentFeatured,
} from '@/components/ui/cardFeatured';
import {
  CarouselFeatured,
  CarouselContentFeatured,
  CarouselItemFeatured,
  CarouselNextFeatured,
  CarouselPreviousFeatured,
} from '@/components/ui/carouselFeatured';
import { cn } from '@/lib/utils';
import {
  fetchItemsThumbnail,
  fetchCategories,
} from '@/backend/strapi/apiService';
import { useEffect, useState } from 'react';
import { Item } from '@/types';
import { Banner } from './Banner';
import { BannerArticulos } from '@/components/BannerArticulos';

interface CategoryItems {
  categoryName: string;
  items: Item[];
}

// Helper function to chunk array into groups of n
function chunkArray<T>(array: T[], size: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
}

export function CarouselItemsShowcase() {
  const [categoriesWithItems, setCategoriesWithItems] = useState<
    CategoryItems[]
  >([]);

  useEffect(() => {
    async function fetchCategoriesAndItems() {
      try {
        const categories = await fetchCategories();

        // Fetch items for each category in parallel
        const categoryItemsPromises = categories.map(async (category) => {
          const items = await fetchItemsThumbnail(category.attributes.name);
          return {
            categoryName: category.attributes.name,
            items: items,
          };
        });

        const results = await Promise.all(categoryItemsPromises);
        // Filter out categories with no items
        const categoriesWithItems = results.filter(
          (result) => result.items.length > 0
        );
        setCategoriesWithItems(categoriesWithItems);
      } catch (error) {
        console.error('Error fetching categories and items', error);
      }
    }

    fetchCategoriesAndItems();
  }, []);

  function normalizeUrl(name: string): string {
    if (!name || typeof name !== 'string') return '';
    return name
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-]/g, '');
  }

  function handleItemRedirect(item: Item) {
    console.log('Item received:', item);

    const itemName = normalizeUrl(item.attributes.name);
    // Type-safe access to category data
    const categoryData = item.attributes.categorias?.data;
    const categoryName =
      categoryData && categoryData.length > 0
        ? normalizeUrl(categoryData[0].attributes.name)
        : '';
    const subcategoryName = normalizeUrl(
      item.attributes.subcategory?.data?.attributes?.name || ''
    );

    // Construct URL with all parts
    const url = `/tienda/${categoryName}/${subcategoryName}/${itemName}`;
    console.log('Generated URL:', url);

    if (itemName && categoryName && subcategoryName) {
      window.location.href = url;
    } else {
      console.warn('Incomplete item details:', {
        itemName,
        categoryName,
        subcategoryName,
        originalItem: item,
      });
    }
  }

  // Chunk categories into groups of 1
  const categoryChunks = chunkArray(
    categoriesWithItems.filter((category) => category.items.length > 0),
    1
  );

  return (
    <>
      {categoryChunks.map((chunk, chunkIndex) => (
        <div key={`chunk-${chunkIndex}`}>
          {/* Render single category */}
          {chunk.map((category) => (
            <div key={category.categoryName}>
              <h2 className="flex justify-start py-3 text-xl capitalize">
                {category.categoryName}
              </h2>
              <CarouselFeatured
                className="w-full overflow-hidden mb-8"
                opts={{
                  loop: true,
                  align: 'start',
                }}
              >
                <CarouselContentFeatured className="-ml-2">
                  {category.items.map((item) => (
                    <CarouselItemFeatured
                      key={item.id}
                      className="pl-1 basis-[65%] lg:basis-1/4 md:basis-1/3 sm:basis-1/2 hover:cursor-pointer"
                      onClick={() => handleItemRedirect(item)}
                    >
                      <div className="p-1 mb-2 h-full">
                        <CardFeatured className="h-full rounded-xl overflow-hidden hover:shadow-lg transition-all duration-300">
                          <CardContentFeatured className="flex flex-col h-full p-6">
                            <div className="flex flex-col h-full justify-between">
                              <div className="w-50 h-40 rounded-lg overflow-hidden">
                                {item.attributes.thumbnail?.data && (
                                  <img
                                    src={
                                      item.attributes.thumbnail.data.attributes
                                        .url
                                    }
                                    alt={
                                      item.attributes.name || 'Product Image'
                                    }
                                    className="object-contain w-full h-full"
                                  />
                                )}
                              </div>
                              <div className="flex flex-col text-center mt-4">
                                <div className="font-medium">
                                  {item.attributes.name}
                                </div>
                              </div>
                            </div>
                          </CardContentFeatured>
                        </CardFeatured>
                      </div>
                    </CarouselItemFeatured>
                  ))}
                </CarouselContentFeatured>

                <CarouselPreviousFeatured
                  className={cn(
                    'absolute h-12 w-12 rounded-full bg-red-200 left-3 top-1/2 -translate-y-1/2'
                  )}
                />
                <CarouselNextFeatured
                  className={cn(
                    'absolute h-12 w-12 rounded-full bg-red-200 right-3 top-1/2 -translate-y-1/2'
                  )}
                />
              </CarouselFeatured>
            </div>
          ))}

          {/* Show Banner for first iteration, BannerArticulos for the rest */}
          {chunkIndex < categoryChunks.length - 1 &&
            (chunkIndex === 0 ? (
              <Banner />
            ) : (
              <BannerArticulos sectionIndex={chunkIndex - 1} />
            ))}
        </div>
      ))}
    </>
  );
}
