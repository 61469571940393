export function BannerArticulos({
  sectionIndex = 0,
}: {
  sectionIndex?: number;
}) {
  const bannerSections = [
    {
      title: 'Artículos Destacados',
      banners: [
        {
          href: 'https://solidoherrajes.com/tienda/muebles/bisagra-cazoleta',
          img: '/bannersArt/bg/Bisagra Cazoleta.png',
          alt: 'Bisagra Cazoleta',
        },
        {
          href: 'https://solidoherrajes.com/tienda/puertas/cerraduras-electronicas',
          img: '/bannersArt/bg/Cerraduras Electronicas.png',
          alt: 'Cerraduras Electronicas',
        },
        {
          href: 'https://solidoherrajes.com/tienda/cajones/correderas-telescopicas',
          img: '/bannersArt/bg/Correderas Telescopicas.png',
          alt: 'Correderas Telescopicas',
        },
      ],
    },
    {
      title: 'Más Artículos',
      banners: [
        {
          href: 'https://solidoherrajes.com/tienda/vestidor/corredizos',
          img: '/bannersArt/bg/Corredizos.png',
          alt: 'Corredizos',
        },
        {
          href: 'https://solidoherrajes.com/tienda/puertas/manijas',
          img: '/bannersArt/bg/Manijas.png',
          alt: 'Manijas',
        },
        {
          href: 'https://solidoherrajes.com/tienda/cajones/matrix-box',
          img: '/bannersArt/bg/Matrix Box.png',
          alt: 'Matrix Box',
        },
      ],
    },
    {
      title: 'Más Artículos',
      banners: [
        {
          href: 'https://www.solidoherrajes.com/tienda/adhesivos/poliuretanicos',
          img: '/bannersArt/bg/Poliuretánicos.png',
          alt: 'Poliuretanicos',
        },
        {
          href: 'https://www.solidoherrajes.com/tienda/adhesivos/siliconas',
          img: '/bannersArt/bg/Siliconas.png',
          alt: 'Siliconas',
        },
      ],
    },
  ];

  const currentSection = bannerSections[sectionIndex % bannerSections.length];

  return (
    <section>
      <h2 className="flex justify-start py-2 text-xl">
        {currentSection.title}
      </h2>
      <div className="flex w-full py-5">
        <div className="flex flex-col lg:flex-row gap-4 w-full">
          {currentSection.banners.map((banner, index) => (
            <div key={index} className="w-full">
              <a href={banner.href}>
                <img
                  className="w-full"
                  src={banner.img}
                  alt={banner.alt}
                  loading="lazy"
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
