import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import BreadcrumbWithCustomSeparator from '@/components/ui/BreadcrumbWithCustomSeparator';
import Products from './Products';
import Filter from '@/components/Filter';
import {
  Product as ProductTypes,
  Filter as FilterTypes,
  FilterCategory,
} from '@/types';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationPrevious,
  PaginationNext,
} from '@/components/ui/pagination';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface BodyShopProps {
  selectedFilter: string;
  onFilterChange: (filter: string) => void;
  searchQuery: string;
  isLargeScreen: boolean;
  products: ProductTypes[];
  filterCategoriesFromSearch: FilterCategory[] | null;
  filtersFromSearch: FilterTypes[] | null;
  loading: boolean;
}

export default function BodyShop({
  onFilterChange,
  selectedFilter,
  searchQuery,
  isLargeScreen: largeScreen,
  products,
  filterCategoriesFromSearch,
  filtersFromSearch,
  loading,
}: BodyShopProps) {
  const location = useLocation();
  const [breadcrumbItems, setBreadcrumbItems] = useState<
    { title: string; path: string; isCurrentPage: boolean }[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 20;
  const topRef = useRef<HTMLDivElement>(null);

  // Breadcrumb effect
  useEffect(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const items = pathSegments.map((segment, index) => ({
      path: '/' + pathSegments.slice(0, index + 1).join('/'),
      title: segment
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '),
      isCurrentPage: index === pathSegments.length - 1,
    }));
    setBreadcrumbItems(items);
  }, [location.pathname]);

  // Scroll effect
  useEffect(() => {
    topRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [currentPage]);

  // Reset page on filter change
  useEffect(() => {
    setCurrentPage(1);
  }, [onFilterChange]);

  const handleFilterChange = (newFilter: string) => {
    onFilterChange(newFilter);
    setCurrentPage(1);
  };

  const currentProducts = products.slice(
    (currentPage - 1) * productsPerPage,
    currentPage * productsPerPage
  );
  const totalPages = Math.ceil(products.length / productsPerPage);

  const renderSearchResults = () => {
    if (!searchQuery) return null;

    return (
      <div className="mb-6 border-b border-gray-200 pb-4">
        <h1 className="text-2xl font-poppins font-semibold text-gray-700">
          {products.length > 0
            ? `Resultados para "${searchQuery}"`
            : `No se encontraron resultados para "${searchQuery}"`}
        </h1>
        {products.length > 0 && (
          <p className="text-gray-500 mt-1">
            {products.length} {products.length === 1 ? 'producto' : 'productos'}{' '}
            encontrado{products.length === 1 ? '' : 's'}
          </p>
        )}
      </div>
    );
  };

  const renderSkeletons = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 animate-pulse">
      {Array.from({ length: 16 }).map((_, index) => (
        <div
          key={index}
          className="bg-white rounded-lg shadow-md overflow-hidden transition-shadow hover:shadow-lg"
        >
          <Skeleton height={240} className="object-cover" />
          <div className="p-4 space-y-3">
            <Skeleton height={24} className="w-3/4" />
            <Skeleton height={20} className="w-1/2" />
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="flex min-h-screen " ref={topRef}>
      {largeScreen && (
        <aside className="w-72 flex-shrink-0 p-6 border-r border-gray-200 bg-white">
          <Filter
            filter={false}
            handleSetFilter={() => {}}
            isLargeScreen={largeScreen}
            onFilterChange={handleFilterChange}
            filtersCategoriesFromSearch={
              filterCategoriesFromSearch || undefined
            }
            filtersFromSearch={filtersFromSearch || undefined}
            products={products}
            selectedFilter={selectedFilter}
            loading={loading}
          />
        </aside>
      )}

      <main className="flex-1 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="mb-6">
          <BreadcrumbWithCustomSeparator items={breadcrumbItems} />
        </div>

        {loading ? (
          renderSkeletons()
        ) : (
          <div className="space-y-8">
            {renderSearchResults()}
            <Products products={currentProducts} />

            {products.length > 0 && (
              <div className="mt-12 flex justify-center">
                <Pagination className="pagination-controls bg-white shadow-sm rounded-lg p-2">
                  <PaginationPrevious
                    isDisabled={currentPage === 1}
                    onClick={() =>
                      currentPage > 1 && setCurrentPage(currentPage - 1)
                    }
                    className="hover:bg-gray-100 transition-colors"
                  />
                  <PaginationContent>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <PaginationItem key={index}>
                        <PaginationLink
                          isActive={currentPage === index + 1}
                          onClick={() => setCurrentPage(index + 1)}
                          className={`cursor-pointer transition-colors ${
                            currentPage === index + 1
                              ? 'bg-primary text-white hover:bg-primary/90'
                              : 'hover:bg-gray-100'
                          }`}
                        >
                          {index + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                  </PaginationContent>
                  <PaginationNext
                    isDisabled={currentPage === totalPages}
                    onClick={() =>
                      currentPage < totalPages &&
                      setCurrentPage(currentPage + 1)
                    }
                    className="hover:bg-gray-100 transition-colors"
                  />
                </Pagination>
              </div>
            )}
          </div>
        )}
      </main>
    </div>
  );
}
