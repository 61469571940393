import { config } from '../config';
import axios from 'axios';
import {
  StrapiResponse,
  Category,
  Subcategory,
  Item,
  Filter,
  FilterCategory,
  Product,
  Image,
  PromotionalBanner,
  FeaturedProduct,
} from '@/types';

const API_URL = 'https://solido-herrajes-strapi.fly.dev/api/';
const apiToken = config.VITE_STRAPI_FRONTEND_PUBLIC_ACCESS_API_TOKEN;

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${apiToken}`,
  },
});

export const getImageUrls = async (
  image: Image | undefined
): Promise<string[]> => {
  const urls: string[] = [];
  if (image) {
    const { formats, url } = image.attributes;

    // Always add the original image URL first
    if (url) {
      urls.push(url);
    }

    // Add formatted image URLs if available
    if (formats) {
      if (formats.large) urls.push(formats.large.url);
      if (formats.medium) urls.push(formats.medium.url);
      if (formats.small) urls.push(formats.small.url);
      if (formats.thumbnail) urls.push(formats.thumbnail.url);
    }
  }

  return urls;
};

export const fetchCategories = async (): Promise<Category[]> => {
  try {
    const response =
      await axiosInstance.get<StrapiResponse<Category>>('/categories');
    return response.data.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

export const fetchSubcategories = async (): Promise<Subcategory[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<Subcategory>>(
      '/subcategories',
      {
        params: {
          populate: 'category', // Populate the 'category' field
        },
      }
    );
    return response.data.data;
  } catch (error) {
    console.error('Error fetching subcategories:', error);
    throw error;
  }
};

export const fetchItems = async (): Promise<Item[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<Item>>('/items', {
      params: {
        populate: 'subcategory,categorias', // Populate 'category' and 'subcategory' fields
      },
    });

    return response.data.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
};

export const fetchItemsThumbnail = async (
  category: string
): Promise<Item[]> => {
  if (category === 'Hogar' || category === 'Vestidor') {
    return []; // Ignore 'hogar' and 'vestidor' categories
  }

  try {
    const response = await axiosInstance.get<StrapiResponse<Item>>('/items', {
      params: {
        'filters[categorias][name][$eq]': category,
        populate: ['thumbnail', 'categorias', 'subcategory'], // Include necessary relations
      },
    });

    return response.data.data;
  } catch (error) {
    console.error('Error fetching items thumbnails:', error);
    throw error;
  }
};

export const fetchFilters = async (): Promise<Filter[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<Filter>>(
      '/filters?populate=*'
    );

    return response.data.data.map((filter) => ({
      ...filter,
      attributes: {
        ...filter.attributes,
      },
    }));
  } catch (error) {
    console.error('Error fetching filters:', error);
    throw error;
  }
};

export const fetchFiltersByCategory = async (
  category: string
): Promise<Filter[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<Filter>>(
      `/filters`,
      {
        params: {
          'filters[category][name][$eq]': category,
          populate: '*',
        },
      }
    );
    return response.data.data.map((filter) => ({
      ...filter,
      attributes: {
        ...filter.attributes,
      },
    }));
  } catch (error) {
    console.error('Error fetching filters based on category:', error);
    throw error;
  }
};

export const fetchFiltersBySubcategory = async (
  subcategory: string
): Promise<Filter[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<Filter>>(
      `/filters`,
      {
        params: {
          'filters[subcategory][name][$eq]': subcategory,
          populate: '*',
        },
      }
    );
    return response.data.data.map((filter) => ({
      ...filter,
      attributes: {
        ...filter.attributes,
      },
    }));
  } catch (error) {
    console.error('Error fetching filters based on subcategory:', error);
    throw error;
  }
};

export const fetchFiltersByItem = async (item: string): Promise<Filter[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<Filter>>(
      `/filters`,
      {
        params: {
          'filter[item][name][$eq]': item,
          populate: '*',
        },
      }
    );
    return response.data.data.map((filter) => ({
      ...filter,
      attributes: {
        ...filter.attributes,
      },
    }));
  } catch (error) {
    console.error('Error fetching filters based on item:', error);
    throw error;
  }
};

export const fetchFiltersByProduct = async (
  product: string
): Promise<Filter[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<Filter>>(
      `/filters`,
      {
        params: {
          'filters[product][name][$eq]': product,
          populate: '*',
        },
      }
    );
    return response.data.data.map((filter) => ({
      ...filter,
      attributes: {
        ...filter.attributes,
      },
    }));
  } catch (error) {
    console.error('Error fetching filters based on product:', error);
    throw error;
  }
};

export const fetchFiltersByFilterCategory = async (
  filterCategory: string
): Promise<Filter[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<Filter>>(
      `/filters`,
      {
        params: {
          'filters[filter_category][name][$eq]': filterCategory,
          populate: '*',
        },
      }
    );
    return response.data.data.map((filter) => ({
      ...filter,
      attributes: {
        ...filter.attributes,
      },
    }));
  } catch (error) {
    console.error('Error fetching filters based on filter_category:', error);
    throw error;
  }
};

export const fetchFilterCategoryByFilter = async (
  filter: string
): Promise<FilterCategory[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<FilterCategory>>(
      `/filter-categories?filters[filters][name][$eq]=${filter}&populate=*`
    );

    return response.data.data.map((filterCategory) => ({
      ...filterCategory,
      attributes: {
        ...filterCategory.attributes,
      },
    }));
  } catch (error) {
    console.error('Error fetching filter_categories based on filter:', error);
    throw error;
  }
};

export const fetchFilterCategoryByItem = async (
  item: string
): Promise<FilterCategory[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<FilterCategory>>(
      `/filter-categories?filter[item][name][$eq]=${item}&populate=*`
    );

    return response.data.data.map((filterCategory) => ({
      ...filterCategory,
      attributes: {
        ...filterCategory.attributes,
      },
    }));
  } catch (error) {
    console.error('Error fetching filter_categories based on item:', error);
    throw error;
  }
};

export const fetchFilterCategoryBySubcategory = async (
  subcategory: string
): Promise<FilterCategory[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<FilterCategory>>(
      `/filter-categories?filters[subcategory][name][$eq]=${subcategory}&populate=*`
    );

    return response.data.data.map((filterCategory) => ({
      ...filterCategory,
      attributes: {
        ...filterCategory.attributes,
      },
    }));
  } catch (error) {
    console.error(
      'Error fetching filter_categories based on subcategory:',
      error
    );
    throw error;
  }
};

export const fetchFilterCategoryByCategory = async (
  category: string
): Promise<FilterCategory[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<FilterCategory>>(
      `/filter-categories?filters[category][name][$eq]=${category}&populate=*`
    );

    return response.data.data.map((filterCategory) => ({
      ...filterCategory,
      attributes: {
        ...filterCategory.attributes,
      },
    }));
  } catch (error) {
    console.error('Error fetching filter_categories based on category:', error);
    throw error;
  }
};

export const fetchFilterCategory = async (): Promise<FilterCategory[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<FilterCategory>>(
      '/filter-categories?populate=*'
    );

    return response.data.data.map((filterCategory) => ({
      ...filterCategory,
      attributes: {
        ...filterCategory.attributes,
      },
    }));
  } catch (error) {
    console.error('Error fetching filter_categories:', error);
    throw error;
  }
};
// end of fetch filter_category

export const fetchProducts = async (): Promise<Product[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<Product>>(
      '/products',
      {
        params: {
          populate: {
            filters: {
              populate: '*',
            },
            filter_categories: {
              populate: '*',
            },
            image: {
              populate: '*',
            },
          },
        },
      }
    );

    const products = await Promise.all(
      response.data.data.map(async (product) => ({
        ...product,
        attributes: {
          ...product.attributes,
          image: { data: product.attributes.image?.data || null },
        },
        imageUrls: await getImageUrls(product.attributes.image?.data?.[0]),
      }))
    );

    return products;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

export const fetchProductsByCategory = async (
  category: string
): Promise<Product[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<Product>>(
      `/products`,
      {
        params: {
          'filters[category][name][$eq]': category,
          populate: {
            filters: {
              populate: '*',
            },
            filter_categories: {
              populate: '*',
            },
            image: {
              populate: '*',
            },
          },
        },
      }
    );

    const products = await Promise.all(
      response.data.data.map(async (product) => ({
        ...product,
        attributes: {
          ...product.attributes,
          image: { data: product.attributes.image?.data || null },
        },
        imageUrls: await getImageUrls(product.attributes.image?.data?.[0]),
      }))
    );

    return products;
  } catch (error) {
    console.error('Error fetching products by category:', error);
    throw error;
  }
};

export const fetchProductsBySubcategory = async (
  subcategory: string
): Promise<Product[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<Product>>(
      `/products`,
      {
        params: {
          'filters[subcategory][name][$eq]': subcategory,
          populate: {
            filters: {
              populate: '*',
            },
            filter_categories: {
              populate: '*',
            },
            image: {
              populate: '*',
            },
          },
        },
      }
    );

    const products = await Promise.all(
      response.data.data.map(async (product) => ({
        ...product,
        attributes: {
          ...product.attributes,
          image: { data: product.attributes.image?.data || null },
        },
        imageUrls: await getImageUrls(product.attributes.image?.data?.[0]),
      }))
    );

    return products;
  } catch (error) {
    console.error('Error fetching products by subcategory:', error);
    throw error;
  }
};

export const fetchProductsByItem = async (item: string): Promise<Product[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<Product>>(
      `/products`,
      {
        params: {
          'filters[item][name][$eq]': item,
          populate: {
            filters: {
              populate: '*',
            },
            filter_categories: {
              populate: '*',
            },
            image: {
              populate: '*',
            },
          },
        },
      }
    );

    const products = await Promise.all(
      response.data.data.map(async (product) => ({
        ...product,
        attributes: {
          ...product.attributes,
          image: { data: product.attributes.image?.data || null },
        },
        imageUrls: await getImageUrls(product.attributes.image?.data?.[0]),
      }))
    );

    return products;
  } catch (error) {
    console.error('Error fetching products by item:', error);
    throw error;
  }
};

export const fetchProductsByFilter = async (
  filter: string
): Promise<Product[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<Product>>(
      '/products',
      {
        params: {
          'filters[filters][name][$eq]': filter,
          populate: {
            filters: {
              populate: '*',
            },
            filter_categories: {
              populate: '*',
            },
            image: {
              populate: '*',
            },
          },
        },
      }
    );

    const products = await Promise.all(
      response.data.data.map(async (product) => ({
        ...product,
        attributes: {
          ...product.attributes,
          image: { data: product.attributes.image?.data || null },
        },
        imageUrls: await getImageUrls(product.attributes.image?.data?.[0]),
      }))
    );

    return products;
  } catch (error) {
    console.error('Error fetching products by filter:', error);
    throw error;
  }
};

export const fetchProductsByName = async (name: string): Promise<Product[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<Product>>(
      '/products',
      {
        params: {
          'filters[name][$eq]': name,
          populate: '*',
        },
      }
    );

    const product = await Promise.all(
      response.data.data.map(async (product) => ({
        ...product,
        attributes: {
          ...product.attributes,
          image: { data: product.attributes.image?.data || null },
        },
        imageUrls: await getImageUrls(product.attributes.image?.data?.[0]),
      }))
    );

    return product;
  } catch (error) {
    console.error('Error fetching product by name:', error);
    throw error;
  }
};

export const fetchProductsByPage = async (
  page: number,
  pageSize: number
): Promise<{ products: Product[]; totalPages: number }> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<Product>>(
      '/products',
      {
        params: {
          populate: '*',
          pagination: {
            page,
            pageSize,
          },
        },
      }
    );

    const products = await Promise.all(
      response.data.data.map(async (product) => ({
        ...product,
        attributes: {
          ...product.attributes,
          image: { data: product.attributes.image?.data || null },
        },
        imageUrls: await getImageUrls(product.attributes.image?.data?.[0]),
      }))
    );

    const totalPages = response.data.meta.pagination.pageCount;

    return { products, totalPages };
  } catch (error) {
    console.error('Error fetching products by page:', error);
    throw error;
  }
};

// fetch promotional banners for /tienda page based on if url ends with /tienda
export const fetchPromotionalBanners = async (): Promise<
  PromotionalBanner[]
> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<PromotionalBanner>>(
      '/promotional-banners',
      {
        params: {
          populate: '*',
        },
      }
    );

    const banners = await Promise.all(
      response.data.data.map(async (banner) => ({
        ...banner,
        attributes: {
          ...banner.attributes,
          image: { data: banner.attributes.image.data || null },
          image_small: { data: banner.attributes.image_small.data || null },
        },
        bannerImageUrls: await getImageUrls(banner.attributes.image.data?.[0]),
        bannerImageSmallUrls: await getImageUrls(
          banner.attributes.image_small.data?.[0]
        ),
      }))
    );

    return banners;
  } catch (error) {
    console.error('Error fetching promotional banners:', error);
    throw error;
  }
};

export const fetchFeaturedProducts = async (): Promise<FeaturedProduct[]> => {
  try {
    const response = await axiosInstance.get<StrapiResponse<FeaturedProduct>>(
      '/featured-products',
      {
        params: {
          'populate[products][fields]': 'name, model',
          'populate[products][populate]': 'image',
        },
      }
    );

    // Create an empty array to hold the modified featured products
    const featuredProducts: FeaturedProduct[] = [];

    // Iterate over the featured products to process each one
    for (const featuredProduct of response.data.data) {
      // Extract products from featuredProduct attributes
      const productsWithImages = [];

      for (const product of featuredProduct.attributes.products.data) {
        // Extract product attributes including image data
        const productAttributes = product.attributes;
        const imageData = productAttributes.image?.data || [];

        // Fetch image URLs if they exist
        const imageUrls = await Promise.all(
          imageData.map((img) => getImageUrls(img))
        );

        productsWithImages.push({
          ...product,
          attributes: {
            ...productAttributes,
            imageUrls: imageUrls.flat(), // Store image URLs
          },
        });
      }

      featuredProducts.push({
        ...featuredProduct,
        attributes: {
          ...featuredProduct.attributes,
          products: {
            data: productsWithImages,
          },
        },
      });
    }

    return featuredProducts;
  } catch (error) {
    console.error('Error fetching featured products:', error);
    throw error;
  }
};
